<template>
  <div>
    <v-alert class="ma-4" v-if="message" color="orange" type="info">{{ message }}</v-alert>

    <div class="mx-4 mt-5" v-if="examStatus != 'inprogress'">
      <v-btn
        v-if="course?.id"
        :to="{
          name: 'training-course',
          params: {
            id: course.id,
          },
        }"
        class="btn-secondary mx-0 mt-0"
        small
        ><v-icon>mdi-chevron-left</v-icon>{{ $t("t_back") }}</v-btn
      >
    </div>
    <v-container class="py-0 px-0 pb-10" fluid v-if="course">
      <v-card flat class="ma-4" tile>
        <div
          class="d-flex px-2 py-1 text-uppercase"
          style="border-bottom: 1px solid #555; background: rgba(0, 0, 0, 0.2); font-size: 12px; letter-spacing: 1px"
        >
          <span v-if="now && end">
            <span v-if="finished" class="font-weight-bold">{{ $t("v_exam.t_time_is_expired") }}</span>
            <span v-else class="text-uppercase">{{ timeDisplay }} {{ $t("v_exam.t_remaining") }}</span>
          </span>
          <v-spacer></v-spacer>
          {{ course.examDurationMinutes }} {{ $t("v_exam.t_minutes") }}
        </div>
        <div class="mx-5">
          <div class="mt-2" v-if="examQuestions" style="margin-left: -12px; margin-right: -12px">
            <span v-for="(q, idx) in examQuestions" :key="idx" class="mr-1">
              <v-btn color="success" class="font-weight-bold" x-small v-if="question == idx"
                ><v-icon v-if="examQuestions[idx].answer" small class="mr-1">mdi-check</v-icon>
                {{ idx + 1 }}
              </v-btn>
              <v-btn
                class="font-weight-bold"
                @click="goToQuestion(q.id, question)"
                x-small
                v-else-if="examQuestions[idx].answer"
              >
                <v-icon color="success" small class="mr-1">mdi-check</v-icon> {{ idx + 1 }}
              </v-btn>
              <v-btn class="font-weight-bold" @click="goToQuestion(q.id, question)" x-small v-else>
                {{ idx + 1 }}
              </v-btn>
            </span>
          </div>
          <div class="question-header py-4 mb-0 text-h5">{{ course.title }}</div>
          <div class="py-9 pt-4" v-if="showStartBtn">
            {{ $t("v_exam.t_the_exam_time_limit_is") }} {{ course.examDurationMinutes }} {{ $t("v_exam.t_minutes") }}.
            {{ $t("v_exam.t_the_minimum_score_to_receive_credit") }} {{ course.passingPercentage * 100 }}%.
            {{ $t("v_exam.t_good_luck") }} <br /><br />
            <v-btn color="primary" @click="startExam">{{ $t("v_exam.t_start_exam") }}</v-btn>
          </div>

          <v-alert color="success" outlined class="mt-4" v-if="showSubmitBtn">
            <div class="text-h6 mb-3">
              {{ $t("v_exam.t_all_questions_have_been_answered") }}
            </div>
            <v-btn color="primary" @click="submitExam">{{ $t("v_exam.t_submit_exam") }}</v-btn>
          </v-alert>

          <div class="py-3" v-if="examQuestions">
            <v-carousel
              :light="$vuetify.theme.dark ? false : true"
              v-model="question"
              hide-delimiters
              :show-arrows="false"
            >
              <v-carousel-item v-for="q in examQuestions" :key="q.id">
                <v-sheet height="100%" tile>
                  <div>
                    <div class="pt-6">
                      <div>{{ q.index }}. {{ q.schema.question }}</div>
                      <div>
                        <v-radio-group
                          v-model="examQuestions.find((question) => question.id == q.id).answer"
                          @change="(value) => inputAnswer(value, q.id)"
                        >
                          <div class="d-flex my-2" v-for="(option, index) in q.schema.options" :key="index">
                            <span class="mr-3">{{ option.option }}. </span
                            ><v-radio :label="option.answer" :value="option.option"></v-radio>
                          </div>
                        </v-radio-group>
                      </div>
                    </div>
                  </div>
                  <div>
                    <v-btn color="primary" @click="prev(q.id)"
                      ><v-icon>mdi-chevron-left</v-icon> {{ $t("t_prev") }}</v-btn
                    >
                    <v-btn class="ml-3" color="primary" @click="next(q.id)"
                      >{{ $t("t_next") }} <v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { TrainingService } from "@/services";
import Helpers from "@/mixins/helpers";
import { DateTime, Duration } from "luxon";

export default {
  name: "TrainingExam",
  mixins: [Helpers],
  props: {},
  computed: {
    remaining() {
      if (!this.end || !this.now) {
        return null;
      }
      return this.end.diff(this.now).toObject();
    },
    timeDisplay() {
      if (!this.end || !this.now) {
        return null;
      }
      return Duration.fromObject(this.remaining).toFormat("h:mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  data: () => ({
    enrollmentId: null,
    enrollment: null,
    course: null,
    examAttempt: null,
    examContent: null,
    examQuestions: null,
    showStartBtn: false,
    showSubmitBtn: false,
    message: "",
    question: 0,
    examStatus: null, //new, inprogress, retake, exhausted
    now: null,
    end: null,
    tick: null,
  }),

  methods: {
    async inputAnswer(val, id) {
      if (val) {
        let params = {
          examAttemptId: this.examAttempt.id,
          courseQuestionId: id,
          answer: val,
        };
        await TrainingService.submitExamAnswer(params);
        this.checkExam();
      }
    },

    checkExam() {
      if (this.examQuestions.length == this.examQuestions.filter((q) => q.answer)?.length) {
        this.showSubmitBtn = true;
      }
    },

    goToQuestion(qId) {
      this.question = this.examQuestions.findIndex((e) => e.id == qId);
    },

    next() {
      this.question = this.question + 1;
    },

    prev() {
      this.question = this.question - 1;
    },

    async getExamStatus() {
      let r = await TrainingService.getExamAttempts({ courseEnrollmentId: this.enrollmentId });
      const examAttempts = r.data;

      if (this.enrollment.expiresOn < DateTime.now().toUTC().toISO()) {
        this.message = this.$t("v_exam.t_enrollment_expired");
        this.examStatus = "expired";
      } else if (this.enrollment.endedOn) {
        this.message = this.$t("v_exam.t_unenrolled");
        this.examStatus = "unenrolled";
      } else if (!examAttempts.length) {
        this.showStartBtn = true;
        this.examStatus = "new";
      } else if (examAttempts.find((a) => a.submittedOn === null && a.expiresOn > DateTime.now().toUTC().toISO())) {
        this.examAttempt = examAttempts.find(
          (a) => a.submittedOn === null && a.expiresOn > DateTime.now().toUTC().toISO(),
        );

        if (this.examAttempt) {
          await this.getExamContent(this.examAttempt.id);
          await this.getExamQuestions(this.examAttempt.id);
          await this.getExamAnswers(this.examAttempt.id);
          this.checkExam();
          this.startTimer();
          this.showStartBtn = false;
          this.examStatus = "inprogress";
        }
      } else if (examAttempts.length >= this.course.reattemptsAllowed + 1) {
        //should not happen unless loading page directly
        this.message = this.$t("v_exam.t_you_have_exceeded_the_number_of_allowed_exam_attempts");
        this.examStatus = "exhausted";
      } else {
        this.showStartBtn = true;
        this.examStatus = "retake";
      }
    },

    async startExam() {
      const r = await TrainingService.startExam(this.enrollmentId);
      const examAttemptId = r.data.examAttemptId;
      if (examAttemptId) {
        const r = await TrainingService.getExamAttempt(examAttemptId);
        this.examAttempt = r.data;
        this.getExamContent(examAttemptId);
        this.getExamQuestions(examAttemptId);
        this.startTimer();
        this.showStartBtn = false;
        this.examStatus = "inprogress";
      }
    },

    async submitExam() {
      await TrainingService.submitExam(this.examAttempt.id);
      this.$router.push({ name: "training-course", params: { id: this.course?.id } });
    },

    startTimer() {
      this.now = DateTime.utc().set({ milliseconds: 0 });
      const expiration = this.examAttempt.expiresOn;
      this.end = DateTime.fromISO(expiration);
      this.tick = setInterval(() => {
        this.now = DateTime.utc().set({ milliseconds: 0 });
      }, 1000);
    },

    async getExamContent(examAttemptId) {
      const r = await TrainingService.getExamContent(examAttemptId);
      this.examContent = r.data;
    },

    async getExamQuestions(examAttemptId) {
      let params = {};
      params.examAttemptId = examAttemptId;
      const r = await TrainingService.getExamQuestions(params);
      this.examQuestions = r.data?.map((q) => ({ ...q, schema: JSON.parse(q.serializedContent) }));
      this.examQuestions.sort((a, b) => a.index - b.index);
      this.prevQuestion = this.examQuestions[0].id;
    },

    async getExamAnswers(examAttemptId) {
      let params = {};
      params.examAttemptId = examAttemptId;
      const r = await TrainingService.getExamAnswers(params);
      const answers = r.data;
      this.examQuestions.forEach((q) => {
        const answer = answers.find((a) => a.courseQuestionId === q.id);
        if (answer) {
          q.answer = answer.answer;
        }
      });
    },

    async getCourse() {
      const r = await TrainingService.getCourse(this.enrollment.courseId);
      this.course = r.data;
    },

    async init() {
      const r = await TrainingService.getEnrollment(this.enrollmentId);
      this.enrollment = r.data;
      await this.getCourse();
      await this.getExamStatus();
    },
  },
  mounted() {
    this.enrollmentId = this.$route.params.id;
    this.init();
  },
};
</script>
